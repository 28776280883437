@keyframes backgroundColorPalette {
    0% {
        background: #ee6055;
    }
    19.9% {
        background: #ee6055;
    }
    20% {
        background: #60d394;
    }
    39% {
        background: #60d394;
    }
    40% {
        background: #aaf683;
    }
    59% {
        background: #aaf683;
    }
    60% {
        background: #ffd97d;
    }
    89% {
        background: #ffd97d;
    }
    90% {
        background: #ff9b85;
    }
    100% {
        background: #ff9b85;
    }
}

.db-of-smiles-container {
    width: 100vw;
    height: 100vh;
    animation-name: backgroundColorPalette;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.dos-header {
    width: 100vw;
    height: 65px;
    position: fixed;
    top: -10px;
    left: 2vw;
    z-index: 100;
    font-family: Moderat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    font-weight: 600;
    color: white;
}

.dos-header-welcome {
    position: absolute;
    top: -32px;
    left: 4vw;
    width: 100%;
}

.dos-header h3 {
    position: absolute;
    font-size: 2em;
}

.dos-header h1,
.dos-header h2,
.dos-header h4 {
    position: absolute;
    left: 10vw;
    top: 10px;
    font-size: 4em;
}

.dos-header h2:first-of-type {
    font-size: 3em;
    left: 20vw;
    top: 19px;
}

.dos-header h2:nth-of-type(2) {
    font-size: 4em;
    left: 25vw;
    top: -5px;
    transform: rotate(-7deg);
}

.dos-header h4 {
    font-size: 1.5em;
    text-decoration: underline;
    left: 25vw;
    top: 95px;
    transform: rotate(-7deg);
    color: black;
    background-color: white;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
}

@media (max-width: 800px) {
    .dos-header h2:nth-of-type(2) {
        font-size: 4em;
        left: 32vw;
        top: 0;
        transform: rotate(-7deg);
    }

    .dos-header h4 {
        font-size: 1.5em;
        text-decoration: underline;
        left: 32vw;
        top: 102px;
        transform: rotate(-7deg);
    }
}

.db-of-smiles-container .centered {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    top: 65px;
    height: calc(100vh - 130px);
    position: fixed;
    overflow: scroll;
}

.circle-container-1 {
    position: absolute;
    width: 10vw;
    height: 10vw;
    padding: 0;
    list-style: none;
    margin: 5em auto 0;
    left: calc(50% - 5vw);
    top: calc(50% - 5vw);
}
.circle-container-1 > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3em;
    width: 150px;
    height: 150px;
}
.circle-container-1 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(10em) rotate(0deg);
}
.circle-container-1 > *:nth-of-type(2) {
    transform: rotate(45deg) translate(10em) rotate(-45deg);
}
.circle-container-1 > *:nth-of-type(3) {
    transform: rotate(90deg) translate(10em) rotate(-90deg);
}
.circle-container-1 > *:nth-of-type(4) {
    transform: rotate(135deg) translate(10em) rotate(-135deg);
}
.circle-container-1 > *:nth-of-type(5) {
    transform: rotate(180deg) translate(10em) rotate(-180deg);
}
.circle-container-1 > *:nth-of-type(6) {
    transform: rotate(225deg) translate(10em) rotate(-225deg);
}
.circle-container-1 > *:nth-of-type(7) {
    transform: rotate(270deg) translate(10em) rotate(-270deg);
}
.circle-container-1 > *:nth-of-type(8) {
    transform: rotate(315deg) translate(10em) rotate(-315deg);
}
.circle-container-1 img {
    display: block;
    max-width: 100%;
    transition: 0.15s;
}

.circle-container-2 {
    position: absolute;
    width: 20vw;
    height: 20vw;
    padding: 0;
    list-style: none;
    margin: 5em auto 0;
    left: calc(50% - 10vw);
    top: calc(50% - 10vw);
}
.circle-container-2 > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3em;
    width: 100px;
    height: 100px;
}

.circle-container-2 > *:nth-of-type(1),
.circle-container-3 > *:nth-of-type(1) {
    transform: rotate(0deg) translate(10em) rotate(0deg);
}
.circle-container-2 > *:nth-of-type(2),
.circle-container-3 > *:nth-of-type(2) {
    transform: rotate(45deg) translate(10em) rotate(-45deg);
}
.circle-container-2 > *:nth-of-type(3),
.circle-container-3 > *:nth-of-type(3) {
    transform: rotate(90deg) translate(10em) rotate(-90deg);
}
.circle-container-2 > *:nth-of-type(4),
.circle-container-3 > *:nth-of-type(4) {
    transform: rotate(135deg) translate(10em) rotate(-135deg);
}
.circle-container-2 > *:nth-of-type(5),
.circle-container-3 > *:nth-of-type(5) {
    transform: rotate(180deg) translate(10em) rotate(-180deg);
}
.circle-container-2 > *:nth-of-type(6),
.circle-container-3 > *:nth-of-type(6) {
    transform: rotate(225deg) translate(10em) rotate(-225deg);
}
.circle-container-2 > *:nth-of-type(7),
.circle-container-3 > *:nth-of-type(7) {
    transform: rotate(270deg) translate(10em) rotate(-270deg);
}
.circle-container-2 > *:nth-of-type(8),
.circle-container-3 > *:nth-of-type(8) {
    transform: rotate(315deg) translate(10em) rotate(-315deg);
}
.circle-container-2 img {
    display: block;
    max-width: 100%;
    transition: 0.15s;
}

.circle-container-3 {
    position: absolute;
    width: 40vw;
    height: 40vw;
    padding: 0;
    list-style: none;
    margin: 5em auto 0;
    left: calc(50% - 20vw);
    top: calc(50% - 20vw);
}
.circle-container-3 > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3em;
    width: 100px;
    height: 100px;
}

#photos {
    line-height: 0;

    -webkit-column-count: 9;
    -webkit-column-gap: 0px;
    -moz-column-count: 9;
    -moz-column-gap: 0px;
    column-count: 9;
    column-gap: 0px;
}

#photos img {
    width: 100% !important;
    height: auto !important;
    z-index: ;
}

@media (max-width: 1200px) {
    #photos {
        -moz-column-count: 7;
        -webkit-column-count: 7;
        column-count: 7;
    }
}
@media (max-width: 800px) {
    #photos {
        -moz-column-count: 5;
        -webkit-column-count: 5;
        column-count: 5;
    }
}

@media (max-width: 400px) {
    #photos {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }
}

.search {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    font-family: Moderat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
}

.searchBox {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.searchBox > * {
    margin: 5px;
    text-align: center;
    font-weight: 600;
    font-family: Moderat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
}

.searchBox > input {
    border: 3px solid #000;
    border-radius: 5px;
    padding: 10px;
}

.photo-miss {
    filter: grayscale(100%);
    z-index: 1;
}

.db-of-smiles-container .Site-footer {
    animation-name: backgroundColorPalette;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    margin: 0 !important;
    padding-left: 12px;
    padding-right: 12px;
    left: 0;
    right: 0;
    position: fixed;
}
