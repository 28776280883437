.Share-title {
    font-size: larger;
}

.Share-container {
    padding-left: 12px;
    padding-right: 12px;
    width: 100vw;
}

.Share-container p,
.Choices p {
    font-family: 'Roboto', sans-serif;
}

.Share-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
}

.Share-body,
.Choices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    transition: all 0.1s;
}

.Share-body > form {
    min-width: 320px;
    transition: all 0.1s;
}

.Choices.row {
    display: flex;
    flex-direction: row;
}

.Choices.text {
    text-align: center;
    font-size: 1.2rem;
}

.Choice {
    margin-left: 36px;
    margin-right: 36px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Choice > i {
    font-size: 48px;
}

.Choice > i.bi-phone {
    animation-fill-mode: bounce;
    animation-duration: 1s;
}

.Choice > i.bi-envelope-paper {
    animation-fill-mode: bounce;
    animation-duration: 1s;
    animation-delay: 0.5s;
}

@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}

.Choice > div {
    font-size: 36px;
}

.Share-form .Link-button {
    border: 1px solid #000;
    background-color: transparent;
    font-size: 1.2em;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
    margin-top: 10px;
    font-family: 'Roboto';
    font-weight: 1000;
    flex: 1;
    color: white;
    background-color: black;
}

.Share-form input {
    flex: 1;
    font-size: 16px;
    border-color: black;
    border-width: 1px;
    padding: 12px;
    margin-bottom: 12px;
}

.destination-field {
    margin-bottom: 6px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 200;
}

.padding {
    margin: 20px;
}

.Share-prompt {
    text-align: center;
    font-weight: 200;
}

.Share-container .Tertiary-button {
    font-size: 16px;
    cursor: pointer;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 200;
    text-transform: uppercase;
}

.share-message a,
span.link {
    text-decoration: underline;
    cursor: pointer;
}
