.Folder-select {
    flex: 5 !important;
}

.Form {
    display: flex;
    flex-direction: column;
}

.Form input,
.Form textarea,
.Form select {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex: 0.5;
    border-width: 1px;
}

.Form label {
    display: flex;
    flex: 0.25;
    max-width: 150px;
    text-transform: capitalize;
}

.Form button {
    font-family: 'Roboto';
}

.Form-error {
    color: red;
    font-size: 0.9em;
}

@media only screen and (min-width: 800px) {
    .Form > div {
        flex: 1;
        flex-direction: row;
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-width: 799px) {
    .Form > div {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }
}

@media only screen and (min-width: 800px) {
    .Form {
        display: flex;
        justify-content: center;
    }
}

.Form .expand {
    flex: 1;
    display: flex;
}

.left-justify {
    justify-content: flex-start;
}

.left-padding {
    margin-left: 4px;
}

.Icon-button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.Icon-button i {
    font-size: 2em;
}

.Editor-container {
    max-height: 50vh;
    overflow: auto;
    border: 1px #000 solid;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 12px;
}

.Editor-row {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center !important;
}

.Editor-row > .Title-row {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 12px);
}

.Editor-row select {
    font-family: 'Roboto Mono';
}

.Link-button {
    background-color: transparent;
    border: none;
    font-size: 1em;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Roboto';
}

.Form-row {
    flex-direction: row;
    display: flex;
    flex: 1;
}

.Read-only-value {
    border: 0.5px solid #333;
    padding: 4px;
    margin-top: 6px;
    font-size: 0.9em;
}

.Fine-print {
    font-size: 0.9em;
}

.Fine-print li {
    text-indent: 0;
    margin-left: 0;
    font-size: 0.8em;
    list-style: none;
}

label.natural-width {
    white-space: nowrap;
    max-width: unset;
}

.Settings-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Form-input-list li > .entry {
    display: flex;
    flex-direction: row;
}

.Form-input-list li > .entry > * {
    margin-left: 4px;
    margin-right: 4px;
}
