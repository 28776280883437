.Table > thead,
.Table > thead span {
    font-size: 16px;
    text-transform: capitalize;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    text-align: left;
    padding-right: 16px;
}

.Table > thead span {
    padding-left: 16px;
}

.Table > thead {
    font-size: 12px;
    line-height: 16px;
}

.Table > tbody > tr:nth-child(odd) {
    background-color: #f2f2f2;
}

.Table td {
    width: fit-content;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 16px;
    padding-left: 16px;
}

.Table-options > i,
.Table-options > a {
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
}

.Table .row {
    display: flex;
    flex-direction: row;
}

.pagination-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.pagination-row select {
    padding-top: 2px;
    padding-bottom: 2px;
}

.pagination-row > * {
    margin-left: 6px;
    margin-right: 6px;
    flex-direction: row;
    display: flex;
}

.Table .image-preview {
    max-width: 100px;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

i.button {
    cursor: pointer;
}

.inline-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-right: 8px;
}

.title-row {
    margin-bottom: 24px;
}

.cursor-pointer {
    cursor: pointer;
}

.select-none {
    user-select: none;
}
