/* roboto-mono-regular - latin */

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-mono-v22-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/roboto-mono-v22-latin-regular.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-mono-v22-latin-regular.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/roboto-mono-v22-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-mono-v22-latin-regular.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/roboto-mono-v22-latin-regular.svg#RobotoMono')
            format('svg'); /* Legacy iOS */
}

/*html {
    font-size: 18px;
}

input {
    font-family: 'Roboto';
}

.App {
    font-family: 'Roboto';
}

a {
    text-decoration: none;
    color: black;
}

.App h1 {
    margin-top: 0px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0.005em;
    font-size: 36px;
    text-transform: uppercase;
}

.App h2 {
    margin-top: 0px;
    font-family: 'Oswald', sans-serif;
    text-transform: capitalize;
    letter-spacing: 0.005em;
}

.App h3 {
    font-family: 'Oswald', sans-serif;
    text-transform: capitalize;
    letter-spacing: 0.005em;
}

.App h4 {
    font-family: 'Oswald', sans-serif;
    text-transform: capitalize;
    letter-spacing: 0.005em;
}

p,
label,
input {
    font-family: 'Roboto Condensed';
}

button {
}

.Site-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.Site-header {
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.Site-header,
.Site-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Site-header h2 {
    margin-bottom: 4px;
    margin-top: 1px;
}

.Site-footer {
    position: absolute;
    bottom: 0px;
    left: 8px;
    right: 8px;
    height: 62px;
}

.Site-footer .logo {
    max-width: 48px;
    max-height: 48px;
}

.Site-footer > caption {
    font-size: 0.8em;
}

div.spacer {
    flex: 1;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.Primary-button {
    color: white;
    border-width: 1px;
    border-color: black;
    background-color: black;
}

.Secondary-button {
    color: black;
    border-width: 1px;
    border-color: black;
    background-color: white;
}

.Primary-button,
.Secondary-button,
.Tertiary-button {
    cursor: pointer;
    font-size: 12pt;
    margin: 2px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 200;
}

.Secondary-button:hover {
    background-color: hotpink;
}
.Layout-row {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.Small-text {
    font-size: 10pt;
}

.Site-menu-container {
    flex-wrap: nowrap;
}

.Menu-open-button {
    display: none;
}

.Menu-close-button {
    display: none;
}

.Menu-background {
    display: none;
}

@media screen and (max-width: 986px) {
    .Site-header > h2 {
        margin-left: 36px;
    }
    .Site-menu-container {
        display: none;
        position: absolute;
    }

    .Menu-background.expanded {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .Site-menu-container.expanded {
        display: flex;
        width: 300px;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.95);
        padding: 12px;
        top: 8px;
        left: 8px;
        bottom: 8px;
        animation: fade-in 0.2s ease-in-out;
        border-radius: 2px;
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
        z-index: 2;
    }

    .Menu-close-button {
        position: absolute;
        right: 8px;
        top: 8px;
        display: block;
    }

    .Menu-open-button {
        display: block;
        position: absolute;
        top: 30px;
        left: 8px;
        font-size: 1.4em;
    }

    .Site-menu-container::before {
        content: 'photostat menu ';
        padding-left: 2px;
        padding-bottom: 12px;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (min-width: 987px) {
    .Site-menu-container {
        margin-left: 24px;
    }

    .Site-menu-container span::after {
        content: ' | ';
        padding-left: 4px;
    }

    .Site-menu-container :last-child::after {
        content: ' ';
    }
}
.Row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Row-container-actions {
    padding-left: 20px;
}

.Site-content.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Front-and-center {
    display: flex;
    flex-direction: column;
    padding: 24px;
    min-height: 400px;
}

.text-button {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}

.thin-title {
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: 100 !important;
}

h3.thin-title {
    text-transform: uppercase;
    font-size: 1.8em;
}

h4.thin-title {
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 8px;
}

.nav-description {
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: 100 !important;
}

[aria-selected='false'] {
    margin-bottom: 0;
}

[aria-selected='true'] {
    background-color: white;
    margin-bottom: 0;
    color: black;
    border-bottom: 3px solid black;
    transform: translateY(2px);
    padding-bottom: 6px;
}

.mobile-menu {
    font-family: 'Roboto Condensed', sans-serif !important;
}

.mobile-menu p {
    font-weight: 100;
    font-size: 16px;
}

.mobile-menu h3 {
    font-weight: 500;
    text-transform: uppercase;
}

[role='tab'] .text-sm {
    font-size: 0.675rem;
}*/

div.spacer {
    flex: 1;
}

/*.Site-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}*/

.clickable {
    cursor: pointer;
}

/* reset */
button {
    all: unset;
}

.SwitchRoot {
    width: 42px;
    height: 25px;
    background-color: #eaeaea;
    border-radius: 9999px;
    border-color: #000000;
    border-width: 2px;
    position: relative;
    box-shadow: 0 2px 10px var(--black-a7);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.SwitchRoot:focus {
    box-shadow: 0 0 0 2px black;
}
.SwitchRoot[data-state='checked'] {
    background-color: black;
}

.SwitchThumb {
    display: block;
    width: 21px;
    height: 21px;
    background-color: rgb(255, 255, 255);
    border-radius: 9999px;
    box-shadow: 0 2px 2px var(--black-a7);
    transition: transform 100ms;
    transform: translateX(2px);
    will-change: transform;
}
.SwitchThumb[data-state='checked'] {
    transform: translateX(19px);
    transition: transform 100ms;
    background-color: rgb(255, 255, 255);
}

.Label {
    color: white;
    font-size: 15px;
    line-height: 1;
}

.height-minus-header {
    min-height: calc(100vh - 64px);
}
