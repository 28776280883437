.Flow-step {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
}

.Flow-step .title {
    font-size: 1.1rem;
    font-weight: bold;
}

.Flow-step .arguments > div {
    font-size: 0.9rem;
    margin-top: 10px;
}

.flow-arrow {
    margin-left: 35px;
    display: block;
    margin-top: -16px;
    font-size: 1.6rem;
    color: #ccc;
}

.test-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.bottom-row {
    align-items: flex-end !important;
}
