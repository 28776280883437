.Gallery {
    height: 100vh;
    width: 100vw;
}

.Gallery-view h1,
.Gallery-view h2,
.Gallery-view h3,
.Gallery-view h4 {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 1em;
}

.hidden-header {
    display: none;
    visibility: hidden;
    opacity: 0;
}

.Gallery-view h1 {
    font-size: 2.2em;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.Gallery-view h2 {
    font-size: 1.9em;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.Gallery-view h3 {
    font-size: 28px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
}

.Gallery-view p,
.Gallery-view span {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 200;
}

.Gallery-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    top: 62px;
    overflow: auto;
    background-color: inherit;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
}

.Gallery-view {
    width: 100%;
}

.Gallery-name {
    color: white;
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 0.3s ease;
    text-align: end;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    text-transform: uppercase;
    padding-right: 10px;
}

.Gallery-name > span {
    max-lines: 1;
    text-overflow: ellipsis;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
}

.Gallery-content > h1 {
    margin-left: 8px;
    margin-top: 16px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 3px;
    font-weight: 800;
    text-transform: capitalize;
}
.Gallery .fullscreen {
    display: flex;
    height: 100vh;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.Gallery-grid-container {
    width: 100%;
    overflow: hidden;
}

.Slideshow-grid-container {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    overflow: hidden;
}

.Gallery-preview-grid-container {
    width: 100%;
    overflow: hidden;
}

.Gallery-grid {
    display: grid;
}

.Gallery-grid.hide {
    display: none;
}

.Video-player {
    position: relative;
}

.Video-player > .click-blocker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Grid-display {
    display: grid;
}

.Grid-display.manager > div {
    padding: 6px;
}

.Gallery-grid-item {
    display: grid;
}

.gallery-cell-preview {
    aspect-ratio: 3/2;
    overflow: hidden;
    position: relative;
    padding: 8px;
}

.gallery-cell-title-container {
    position: absolute;
    bottom: 16px;
    left: 32px;
    right: 32px;
    background-color: rgba(23, 133, 188, 0.9);
    color: white;
    padding: 12px;
    height: 20%;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    text-transform: capitalize;
    max-lines: 2;
    word-wrap: break-word;
    letter-spacing: 0.2rem;
}

@media only screen and (max-width: 600px) {
    .Gallery-grid > div > div {
        grid-template-columns: repeat(auto-fill, calc(100vw - 24px));
    }
    .gallery-cell-preview {
        max-width: 100%;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1279px) {
    .Gallery-grid > div > div {
        grid-template-columns: repeat(auto-fill, minmax(160px, 49.9%));
    }
    .gallery-cell-preview {
        min-width: 49.9%;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1500px) {
    .Gallery-grid > div > div {
        grid-template-columns: repeat(auto-fill, minmax(160px, 33.3%));
    }
    .gallery-cell-preview {
        min-width: 33.3%;
    }
}

@media only screen and (min-width: 1500px) {
    .Gallery-grid > div > div {
        grid-template-columns: repeat(auto-fill, minmax(160px, 25%));
    }

    .gallery-cell-preview {
        min-width: 25%;
    }
}

@media only screen and (min-width: 1700px) {
    .Gallery-grid > div > div {
        grid-template-columns: repeat(auto-fill, minmax(160px, 20%));
    }
    .gallery-cell-preview {
        min-width: 20%;
    }
}

.Media-stats-overlay {
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    right: 0px;
    left: 0px;
}

.Media-stats-overlay > .stat {
    font-size: 12px;
}

.Gallery-cell,
.AbsoluteGallery-cell {
    display: flex;
    flex-direction: column;
}

.Gallery-cell .spacer,
.AbsoluteGallery-cell .spacer {
    flex: 1;
}

.Gallery-cell img,
.AbsoluteGallery-cell img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100vh;
}

.Gallery-preview-cell img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100vh;
}

.Gallery-cell.expanded,
.AbsoluteGallery-cell.expanded {
    width: 100vw;
    max-height: 100vh;
    -webkit-transform: translate3d(0, 0, 0);
    z-index: 98;
    position: fixed;
    background-color: rgba(255, 255, 255, 1);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation-duration: 0.2s;
    animation-name: scaleUp;
    margin: 0;
}

.Gallery-cell > .options,
.AbsoluteGallery-cell > .options {
    display: none;
}

.Gallery-cell.expanded > .options {
    display: flex;
    position: fixed;
    padding: 16px;
    z-index: 1002;
    font-size: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: white;
}

.AbsoluteGallery-cell.expanded > div > .options {
    display: flex;
    padding: 16px;
    z-index: 1002;
    font-size: 20px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.Gallery-cell.expanded > .options i,
.AbsoluteGallery-cell.expanded > div > .options i {
    font-size: 20px;
    cursor: pointer;
}

.Gallery-cell.expanded > .options i.save-button,
.AbsoluteGallery-cell.expanded > .options i.save-button {
    font-size: 28px;
}

@media only screen and (max-width: 600px) {
    .Gallery-cell.expanded > .options,
    .AbsoluteGallery-cell.expanded > .options {
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.Close-lightbox {
    position: fixed;
    top: 6px;
    right: 6px;
    z-index: 1001;
    font-size: 28px;
    cursor: pointer;
}

.Gallery-cell.expanded video {
    max-height: 80vh;
    z-index: 99;
    margin-top: 100px;
}
.AbsoluteGallery-cell.expanded video {
    z-index: 99;
    max-height: 85vh;
}

@media only screen and (min-width: 601px) {
    .Gallery-cell.expanded > .options,
    .AbsoluteGallery-cell.expanded > .options {
        bottom: 0;
        top: 0;
        left: 0;
        width: 30px;
        justify-content: flex-start;
        flex-direction: column;
    }

    .Gallery-cell.expanded > .options,
    .AbsoluteGallery-cell.expanded > .options {
        bottom: 0;
        top: 0;
        left: 0;
        width: unset;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    .Gallery-cell.expanded img,
    .Gallery-cell.expanded video {
        padding-left: 50px;
        max-height: 80vh;
    }

    .Gallery-cell.expanded.centered img,
    .Gallery-cell.expanded.centered video {
        padding-left: unset;
        max-height: 80vh;
    }
}

.Gallery-cell.expanded > .share-row > i,
.AbsoluteGallery-cell.expanded > .share-row > i {
    padding-left: 24px;
    padding-right: 24px;
}

.Gallery-cell.expanded > .share-row > i.bi-envelope-paper,
.AbsoluteGallery-cell.expanded > .share-row > i.bi-envelope-paper {
    margin-left: 6px;
}

.Gallery-cell.expanded > .share-row,
.AbsoluteGallery-cell.expanded > .share-row {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    z-index: 1000;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 54px;
    display: flex;
}

.Gallery-cell.expanded i,
.AbsoluteGallery-cell.expanded i {
    cursor: pointer;
}

.Gallery-cell.expanded.image,
.AbsoluteGallery-cell.expanded.image {
    display: flex;
    z-index: 99;
    justify-content: center;
}

.Gallery-cell.expanded img,
.AbsoluteGallery-cell.expanded img {
    max-height: 80vh;
    z-index: 99;
    margin-top: 3vw;
}

.Gallery-cell.expanded > .close > span,
.AbsoluteGallery-cell.expanded > .close > span {
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
}

@keyframes scaleUp {
    from {
        margin: 0px;
    }

    to {
        margin: 0;
    }
}

.Gallery-preview-cell,
.AbsoluteGallery-preview-cell {
    position: relative;
}

.Gallery-cell,
.AbsoluteGallery-cell {
    position: relative;
}

.Gallery-cell.expanded > .Share-button,
.AbsoluteGallery-cell.expanded > .Share-button {
    animation-delay: 0.2;
    animation-duration: 0.2s;
    animation-name: slideIn;
}

@media screen and (max-width: 600px) {
    .close.video {
        margin-top: 120px !important;
    }
}

@keyframes slideIn {
    from {
        left: -256px;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}

@media only screen and (min-width: 600px) {
    .Share-button {
        position: fixed;
        left: 12px;
        top: 12px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 8px;
    }
}

@media only screen and (max-width: 600px) {
    .Share-button {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

.Share-button {
    z-index: 701;
    display: flex;
    flex-direction: column;
    opacity: 1;
    padding: 8px;
    background-color: white;
}

.Share-button > span {
    font-weight: 800;
    margin-left: 4px;
    margin-top: 0px;
    font-size: 13px;
    user-select: none;
    text-align: center;
}

.Share-button.expanded > span {
    font-size: 22px;
    line-height: 2.3;
}

.Gallery-photo-container {
    padding-top: 64px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    display: flex;
}

.Gallery-photo-container > .spacer {
    flex: 1;
}

.Gallery-photo-container > .media {
    flex: 8;
    max-height: 100vh;
}

@media only screen and (max-width: 600px) {
    .Gallery-photo-container > .spacer {
        flex: 0.1;
    }
    .Gallery-photo-container > .media {
        flex: 10;
        max-height: 100vh;
    }
}

.Gallery-navigation {
    display: flex;
    flex-direction: column;
}

.Gif-frame {
    position: relative;
}

.Gif-frame > .button-container {
    left: 10px;
    position: absolute;
    top: 10px;
    width: 45px;
    height: 45px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.button-container > i {
    animation: pulse 5s ease-in-out infinite;
    color: white;
    font-size: 36px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.12);
    }

    100% {
        transform: scale(1);
    }
}

.vjs-tech {
    max-height: 90vh;
}

.video-js {
    background-color: rgba(255, 255, 255, 0.9) !important;
    max-height: 90vh;
}

.Media-container-max {
    max-height: 100vh;
}

.Media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.share-options {
    position: fixed;
    bottom: 0px;
    width: 100vw;
}

.share-options > .center {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding: 20px;
}

.video-js {
    background-color: rgba(255, 255, 255, 0.9) !important;
}

.Share-pane {
    font-family: 'Roboto Condensed', sans-serif;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    /*position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;*/
    width: 100vw;
    padding-top: 22px;
}

.Share-pane > .code-container {
    border: 0px white solid;
    padding-bottom: 10px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1000ms normal ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.Share-pane span {
    cursor: pointer;
}

.Gallery-view > .Site-footer {
    z-index: 900;
    padding: 0;
    margin: 0;
}

.SB-Modal-container,
.Share-modal-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 100ms normal ease-in;
}

.SB-Modal,
.Share-modal {
    position: absolute;
    height: 600px;
    left: calc(100vw / 2 - 200px);
    right: calc(100vw / 2 + 200px);
    top: calc(100vh / 2 - 225px);
    background-color: white;
    z-index: 1000;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-delay: 0ms;
    animation-name: fadeIn;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: both;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    outline: 1px solid #000000;
    width: 400px;
    height: 400px;
}

@media screen and (max-width: 800px) {
    .SB-Modal,
    .Share-modal {
        left: 10px;
        right: 10px;
        width: calc(100% - 20px);
    }

    .Gallery-view h2 {
        font-size: 26px;
    }
}

@keyframes appear {
    from {
        border-radius: 1000px;
        width: 0;
        height: 0;
        left: 50%;
        top: 50%;
    }

    to {
        border-radius: 10px;
        width: 360px;
        height: 400px;
    }
}

/*.SB-Modal.fancy,
.Share-modal.fancy {
    animation: wave 5s infinite ease-in-out;
}*/

@keyframes wave {
    from {
        transform: translate(-2px, 0px);
        box-shadow: rgba(237, 149, 137, 0.4) 5px 5px,
            rgba(237, 149, 137, 0.3) 10px 10px,
            rgba(237, 149, 137, 0.2) 15px 15px,
            rgba(237, 149, 137, 0.1) 20px 20px,
            rgba(237, 149, 137, 0.05) 25px 25px;
    }

    25% {
        transform: translate(10px, 0px);
        box-shadow: rgba(237, 149, 137, 0.4) -5px 5px,
            rgba(237, 149, 137, 0.3) -10px 10px,
            rgba(237, 149, 137, 0.2) -15px 15px,
            rgba(237, 149, 137, 0.1) -20px 20px,
            rgba(237, 149, 137, 0.05) -25px 25px;
    }

    50% {
        transform: translate(10px, 10px);
        box-shadow: rgba(237, 149, 137, 0.4) -5px -5px,
            rgba(237, 149, 137, 0.3) -10px -10px,
            rgba(237, 149, 137, 0.2) -15px -15px,
            rgba(237, 149, 137, 0.1) -20px -20px,
            rgba(237, 149, 137, 0.05) -25px -25px;
    }

    75% {
        transform: translate(-2px, 10px);
        box-shadow: rgba(237, 149, 137, 0.4) 5px -5px,
            rgba(237, 149, 137, 0.3) 10px -10px,
            rgba(237, 149, 137, 0.2) 15px -15px,
            rgba(237, 149, 137, 0.1) 20px -20px,
            rgba(237, 149, 137, 0.05) 25px -25px;
    }

    to {
        transform: translate(-2px, 0px);
        box-shadow: rgba(237, 149, 137, 0.4) 5px 5px,
            rgba(237, 149, 137, 0.3) 10px 10px,
            rgba(237, 149, 137, 0.2) 15px 15px,
            rgba(237, 149, 137, 0.1) 20px 20px,
            rgba(237, 149, 137, 0.05) 25px 25px;
    }
}

.cta-button {
    border: 1px solid black;
    width: auto;
    padding-left: 25px;
    border-radius: 2px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 10px;
    font-size: 18px;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.cta-button.no-border {
    border: none;
}

.cta-button.disabled {
    background-color: lightgray;
}

.cta-button.small {
    font-size: 0.8rem;
    padding-left: 16px;
    padding-right: 16px;
}

.cta-button.animate {
    animation: pulse 8s infinite ease-in-out;
}

.button-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Gallery-message {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 84px;
}

.Fullscreen-modal {
    position: fixed;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 99;
    animation: fadeIn 200ms normal;
    display: flex;
}

.close-modal {
    right: 10px;
    top: 10px;
    position: absolute;
    font-size: 28px;
    z-index: 10003;
    cursor: pointer;
}

.SmileboothAdvert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SmileboothAdvert > img {
    width: 64px;
    height: 64px;
}

@media screen and (min-width: 768px) {
    .tilting-card-content {
        --perspective: 200px;
        --rotateX: 0;
        --rotateY: 0;
        --angle: 1.5deg;

        position: relative;
        display: grid;
        place-content: center;
        text-align: center;
        box-shadow: var(--shadow);
        transform: perspective(var(--perspective)) rotateX(var(--rotateX))
            rotateY(var(--rotateY));
        transition: transform 350ms ease;
    }

    .tilting-card-content > :where(h1, p) {
        background: white;
        margin: 0;
        padding: 0.5rem;
    }

    .mouse-position-tracker {
        position: absolute;
        inset: 0;
    }

    .mouse-position-tracker > div {
        position: absolute;
        width: calc(100% / 3);
        height: calc(100% / 3);
    }

    .tilting-card-content:has(
            .mouse-position-tracker > div:nth-child(1):hover
        ) {
        --rotateX: var(--angle);
        --rotateY: calc(var(--angle) * -1);
    }

    .tilting-card-content:has(
            .mouse-position-tracker > div:nth-child(2):hover
        ) {
        --rotateX: var(--angle);
    }

    .tilting-card-content:has(
            .mouse-position-tracker > div:nth-child(3):hover
        ) {
        --rotateX: var(--angle);
        --rotateY: var(--angle);
    }

    .tilting-card-content:has(
            .mouse-position-tracker > div:nth-child(4):hover
        ) {
        --rotateY: calc(var(--angle) * -1);
    }

    .tilting-card-content:has(
            .mouse-position-tracker > div:nth-child(6):hover
        ) {
        --rotateY: var(--angle);
    }

    .tilting-card-content:has(
            .mouse-position-tracker > div:nth-child(7):hover
        ) {
        --rotateX: calc(var(--angle) * -1);
        --rotateY: calc(var(--angle) * -1);
    }

    .tilting-card-content:has(
            .mouse-position-tracker > div:nth-child(8):hover
        ) {
        --rotateX: calc(var(--angle) * -1);
    }

    .tilting-card-content:has(
            .mouse-position-tracker > div:nth-child(9):hover
        ) {
        --rotateX: calc(var(--angle) * -1);
        --rotateY: var(--angle);
    }

    .mouse-position-tracker > div:nth-of-type(3n - 2) {
        left: 0;
    }
    .mouse-position-tracker > div:nth-of-type(3n - 1) {
        left: calc(100% / 3);
    }
    .mouse-position-tracker > div:nth-of-type(3n) {
        right: 0;
    }
    .mouse-position-tracker > div:nth-child(n + 1):nth-child(-n + 3) {
        top: 0;
    }
    .mouse-position-tracker > div:nth-child(n + 4):nth-child(-n + 6) {
        top: calc(100% / 3);
    }
    .mouse-position-tracker > div:nth-child(n + 7):nth-child(-n + 9) {
        bottom: 0;
    }
}
.expanded-view > .mouse-position-tracker {
    display: none;
}

.Find-your-own-photos-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 5px;
    font-size: 16px;
    z-index: 500;
    height: 32px;
}

.Gallery-header {
    --padding-value: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--padding-value);
    padding-right: var(--padding-value);
    padding-top: var(--padding-value);
    padding-bottom: var(--padding-value);
    background-color: black;
    z-index: 98;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.Gallery-header-title {
    height: 35px;
}

.Gallery-header-title > img {
    height: 35px;
    object-fit: contain;
    padding-right: 8px;
}

.Gallery-previews {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

.gallery-cell-placeholder-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.9);
}

.Rotating-image {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background: linear-gradient(232deg, #fdc9fb, #b8f5f0, #fbd89f, #fb9fae);
    background-size: 800% 800%;

    -webkit-animation: image-load-background 8s ease infinite;
    -moz-animation: image-load-background 8s ease infinite;
    animation: image-load-background 8s ease infinite;
}

@-webkit-keyframes image-load-background {
    0% {
        background-position: 68% 0%;
    }
    50% {
        background-position: 33% 100%;
    }
    100% {
        background-position: 68% 0%;
    }
}
@-moz-keyframes image-load-background {
    0% {
        background-position: 68% 0%;
    }
    50% {
        background-position: 33% 100%;
    }
    100% {
        background-position: 68% 0%;
    }
}
@keyframes image-load-background {
    0% {
        background-position: 68% 0%;
    }
    50% {
        background-position: 33% 100%;
    }
    100% {
        background-position: 68% 0%;
    }
}

.Rotating-image > * {
    animation: side-in 10s infinite linear;
}

@keyframes slide-in {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(25%);
    }
    50% {
        transform: translateX(50%);
    }
    75% {
        transform: translateX(75%);
    }
    100% {
        transform: translateX(0%);
    }
}

.gallery-cell-title-container > .date {
    font-size: 0.9rem;
    padding-top: 8px;
}

.year-filter {
    position: relative;
    color: white;
    font-size: 1.6rem;
    font-family: 'Roboto';
    font-weight: 800;
    letter-spacing: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.year-filter > i:first-child {
    font-size: 1.5rem;
    padding-right: 12px;
}

.year-filter > i:last-child {
    font-size: 1.5rem;
    padding-left: 12px;
    transition: width 200ms ease;
    opacity: 1;
    width: 20px;
}

.year-filter > i:last-child::before {
    transition: width 200ms ease, opacity 200ms ease;
}

.year-filter > i.hidden,
.year-filter > i.hidden::before {
    width: 0px;
    opacity: 0;
}

.white-text {
    color: white;
}

.Find-title {
    font-size: 24px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
    width: 100%;
    padding-bottom: 12px;
}

.Find-subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 200;
    width: 100%;
}

.SB-Modal {
    padding-left: 24px;
    padding-right: 24px;
}

.Gallery-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    font-size: 1rem;
    font-weight: 800;
    cursor: pointer;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
}

.Gallery-cta > img {
    max-width: 100%;
    height: auto;
}

.Photo-buttons {
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
}

.Photo-buttons i {
    padding: 12px;
    cursor: pointer;
}

h3.looking-for-prompt {
    font-size: 18px;
    font-weight: 200;
}

.starting-visible {
    visibility: visible;
    opacity: 1;
}

.fade-out-5 {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 5s, opacity 5s linear;
}

.hide-after-timeout {
    opacity: 0;
    transition: opacity 5s linear;
}

.hide-after-timeout:hover {
    opacity: 1;
}

.loading-pulse {
    transition: all 1s ease;
}
/* HTML: <div class="loader"></div> */
.loader {
    /*width: 90px;
    height: 14px;
    --c: #fff 92%, #0000;
    background: radial-gradient(circle closest-side, var(--c)) calc(100% / -4) 0,
        radial-gradient(circle closest-side, var(--c)) calc(100% / 4) 0;
    background-size: calc(100% / 2) 100%;
    animation: l14 1.5s infinite;
    opacity: 0.5;*/
    /* HTML: <div class="loader"></div> */
    width: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 #0004;
    animation: l1 1s infinite;
}

@keyframes l1 {
    100% {
        box-shadow: 0 0 0 30px #0000;
    }
}
@keyframes l14 {
    0% {
        background-position: calc(100% / -4) 0, calc(100% / 4) 0;
    }
    50% {
        background-position: calc(100% / -4) -14px, calc(100% / 4) 14px;
    }
    100% {
        background-position: calc(100% / 4) -14px, calc(3 * 100% / 4) 14px;
    }
}
